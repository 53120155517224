import(/* webpackMode: "eager" */ "/builds/dr-pam/marketing-site/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/marketing-site/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/marketing-site/public/images/backgrounds/Image_PossumsEtching_Light.png");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/marketing-site/public/images/drpam/portrait-dr-pam.avif");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/dr-pam/marketing-site/src/components/home/Mini-Programs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/dr-pam/marketing-site/src/components/misc/NewsletterModalWrapper.tsx");
